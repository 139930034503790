import { type } from 'os';
import { toast } from 'react-toastify';
import { donationSubscription, donationPurchase } from 'apis/donation';
import { translate as t } from 'utils/helpers';
import {
  confirmPayment,
  confirmPurchase,
  confirmYearlyPayment,
} from '../apis/subscription';

async function upgradeToYearlySubscription(podcast, interval, coupon) {
  let paymentIntent = null;
  const response = await confirmYearlyPayment({
    podcast_id: podcast.id,
    billing_cycle: interval,
    coupon_id: coupon?.id,
  });
  if (response?.status === 201 || response?.status === 200) {
    // For 3DS cards, subscription has pending_update  until the payment is confirmed
    if (response?.data?.subscription?.pending_update)
      paymentIntent = response?.data?.payment_intent;
  }
  return paymentIntent;
}

async function createSubscription(podcast, interval, coupon) {
  let paymentIntent = null;
  let purchaseError = null;
  const response = await confirmPayment({
    podcast_id: podcast.id,
    billing_cycle: interval,
    coupon_id: coupon?.id,
  });
  if (response?.status === 201 || response?.status === 200) {
    if (
      response?.data?.subscription &&
      response?.data?.subscription?.status !== 'active' &&
      response?.data?.subscription?.status !== 'trailing'
    )
      paymentIntent =
        response?.data?.subscription?.latest_invoice?.payment_intent;
  } else {
    purchaseError = response?.data;
  }
  return { purchaseError, paymentIntent };
}

async function createPurchase(audioSeries, coupon) {
  let paymentIntent = null;
  let purchaseError = null;
  const response = await confirmPurchase({
    audio_series_id: audioSeries?.id,
    coupon_id: coupon?.id,
  });
  if (response?.status === 201 || response?.status === 200)
    paymentIntent = response?.data?.payment_intent;
  else {
    purchaseError = response?.data;
  }
  return { purchaseError, paymentIntent };
}

function getStripePurchaseHandler(stripe, onSuccess, onError) {
  async function inner(audio, interval, coupon = null) {
    const { purchaseError, paymentIntent } =
      audio.audio_type === 'podcast'
        ? await createSubscription(audio, interval, coupon)
        : await createPurchase(audio, coupon);
    if (paymentIntent) {
      // Need to confirm the stripe payment intent
      await stripe
        .confirmCardPayment(paymentIntent.client_secret)
        .then((result) => {
          if (result?.error) onError(result?.error);
          else onSuccess();
        });
    } else if (purchaseError) {
      return onError(purchaseError);
    } else onSuccess();
    // If we don't need to confirm payment we just execute the success method
  }
  return inner;
}

function getSubscriptionUpgradeHandler(stripe, onSuccess, onError) {
  async function inner(podcast, interval, coupon = null) {
    const paymentIntent = await upgradeToYearlySubscription(
      podcast,
      interval,
      coupon,
    );
    if (paymentIntent) {
      // Need to confirm the stripe payment intent
      const { error } = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
      );
      if (error) onError(error);
      else onSuccess();
    } else onSuccess();
  }
  return inner;
}

async function createDonationSubscription(donationObj, interval) {
  let paymentIntent = null;
  const response = await donationSubscription({
    donation_id: donationObj.id,
    billing_cycle: interval,
    price: donationObj?.price,
  });
  if (response?.status === 201 || response?.status === 200) {
    paymentIntent =
      response?.data?.subscription?.latest_invoice?.payment_intent;
    return { paymentIntent };
  }
  if (response?.data?.errors?.non_fields_errors.length) {
    toast.error(response?.data?.errors?.non_fields_errors[0]);
  }
  return { paymentIntent, error: response?.data?.errors };
}

async function createDonationPurchase(donationObj) {
  let paymentIntent = null;
  const response = await donationPurchase({
    donation_id: donationObj?.id,
    price: donationObj?.price,
  });
  if (response?.status === 201 || response?.status === 200) {
    paymentIntent = response?.data?.payment_intent;
    return { paymentIntent };
  }

  if (response?.data?.errors?.non_field_errors?.length) {
    toast.error(response?.data?.errors?.non_field_errors[0]);
  }
  return { paymentIntent, error: response?.data?.errors };
}

function getStripeDonationPurchaseHandler(stripe, onSuccess, onError) {
  async function inner(donationObj) {
    const { paymentIntent, error } =
      donationObj.type === 0
        ? await createDonationSubscription(donationObj)
        : await createDonationPurchase(donationObj);
    if (paymentIntent) {
      // Need to confirm the stripe payment intent
      await stripe
        .confirmCardPayment(paymentIntent.client_secret)
        .then((stripeResult) => {
          if (stripeResult?.error) {
            onError(stripeResult?.error);
          } else onSuccess(stripeResult?.paymentIntent);
        });
    } else {
      onError(error);
    }
    // If we don't need to confirm payment we just execute the success method
  }
  return inner;
}

export {
  createSubscription,
  createPurchase,
  getStripePurchaseHandler,
  getSubscriptionUpgradeHandler,
  createDonationSubscription,
  createDonationPurchase,
  getStripeDonationPurchaseHandler,
};
