import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LinkedinShareButton
} from 'react-share';
import styles from './Popup.module.scss';

const SharePopup = ({
  show,
  onHide,
  host,
  modalTitle,
  shareText,
  shareUrl,
  hideVia,
}) => {
  return (
    <Modal
      className={`${styles.modalParent} ${styles.sharePopup}`}
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header className={styles.modalHeader} closeButton />
      <Modal.Body className={styles.modalBody}>
        <h4 className={styles.modalTitle}>{modalTitle}</h4>
        <FacebookShareButton
          className={styles.singleShareButtonWrap}
          url={shareUrl}
          quote={shareText}>
          <Button
            className={styles.singleShareButton}
            variant="outline-primary">
            <img src="/social-icons/facebook-icon.svg" alt="Facebook" />
            Facebook
          </Button>
        </FacebookShareButton>
        <TwitterShareButton
          className={styles.singleShareButtonWrap}
          url={shareUrl}
          title={shareText}
          via={`${hideVia ? '' : 'getmumbler'}`}>
          <Button
            className={styles.singleShareButton}
            variant="outline-primary">
            <img src="/social-icons/twitter-icon.svg" alt="Twitter" />
            Twitter
          </Button>
        </TwitterShareButton>
        <LinkedinShareButton
          className={styles.singleShareButtonWrap}
          url={shareUrl}
          title={shareText}>
          <Button
            className={styles.singleShareButton}
            variant="outline-primary">
            <img src="/social-icons/linkedin-icon.svg" alt="Linkedin" />
            Linkedin
          </Button>
        </LinkedinShareButton>
        <TelegramShareButton
          className={styles.singleShareButtonWrap}
          url={shareUrl}
          title={shareText}>
          <Button
            className={styles.singleShareButton}
            variant="outline-primary">
            <img src="/social-icons/telegram-icon.svg" alt="Telegram" />
            Telegram
          </Button>
        </TelegramShareButton>

        <WhatsappShareButton
          className={styles.singleShareButtonWrap}
          url={shareUrl}
          title={shareText}>
          <Button
            className={styles.singleShareButton}
            variant="outline-primary">
            <img src="/social-icons/whatsapp-icon.svg" alt="Whatsapp" />
            Whatsapp
          </Button>
        </WhatsappShareButton>
      </Modal.Body>
    </Modal>
  );
};
SharePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  hideVia: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  host: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  shareText: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default SharePopup;
